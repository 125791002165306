import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import moment  from "moment"
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useInvoicesList() {
  // Use toast
  const toast = useToast();

  const refInvoiceListTable = ref(null);

  const changeText = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "ยกเลิก ";
        break;
      case 1:
        text = "รับคำสั่งซื้อ";
        break;
      case 2:
        text = "รอชำระ";
        break;
      case 3:
        text = "ยืนยันคำสั่งซื้อ";
        break;
      case 4:
        text = "กำลังจัดส่ง";
        break;
      case 5:
        text = "จัดส่งแล้ว";
        break;
      default:
        text = "รับคำสั่งซื้อ";
    }
    return text;
  };

  const changeTextP = (val) => {
    let text = "";
    switch (val) {
      case 0:
        text = "";
        break;
      case 1:
        text = "โอนเงิน";
        break;
      case 2:
        text = "COD";
        break;
      case 3:
        text = "CreditCard";
        break;
    
      default:
        text = "รอการชำระเงิน ";
    }
    return text;
  };
  // Table Handlers
  const tableColumns = [
    { key: "index", label: "ลำดับ" },
    { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
    {
      key: "createdDateText",
      label: "วันที่สั่งซื้อ",
     
    },
    { key: "contactName", label: "ชื่อ - นามสกุล" },
    { key: "contactMobile", label: "เบอร์โทรศัพท์" },
    {
      key: "totalPriceStd",
      label: "ยอดคำสั่งซื้อ (บาท)",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    }, // ไม่มี
    {
      key: "orderStatus",
      label: "สถานะคำสั่งซื้อ",
      formatter: (val) => changeText(val),
    }, // ไม่มี
    { key: "userName", label: "ชื่อผู้ใช้งาน" },
    // { key: "userFullName", label: "ชื่อตัวแทน" }, // ไม่มี
    { key: "orderLists", label: "รายละเอียดสินค้า" },
    { key: "isPrinted", label: "สถานะปริ๊น" , thStyle: { width: "12%" },}, 
    { key: "action", label: "ปริ๊นใบปะหน้า" }, 
  ];
  const tableColumnsPrint = [
    { key: "radio", label: "#" },
    { key: "index", label: "ลำดับ" },
    { key: "orderNo", label: "หมายเลขคำสั่งซื้อ" },
    {
      key: "createdDateText",
      label: "วันที่สั่งซื้อ",

    },
    { key: "contactName", label: "ชื่อ - นามสกุล" },
    { key: "contactMobile", label: "เบอร์โทรศัพท์" },
    {
      key: "totalPriceStd",
      label: "ยอดคำสั่งซื้อ (บาท)",
      formatter: (val) => `${val.toLocaleString("en-US")}`,
    }, // ไม่มี
    {
      key: "orderStatus",
      label: "สถานะคำสั่งซื้อ",
      formatter: (val) => changeText(val),
    }, // ไม่มี
    // { key: "userFullName", label: "ชื่อตัวแทน" }, // ไม่มี
    { key: "orderLists", label: "รายละเอียดสินค้า" },
    { key: "isPrinted", label: "สถานะปริ๊น" }, 
    { key: "action", label: "ปริ๊นใบปะหน้า" }, 
    
  ];

  

  // const userInfo = localStorage.getItem("searchUser");
  // const userInfoParsed = JSON.parse(userInfo);
  // console.log(userInfoParsed);

  // const dateset = localStorage.getItem("date");
  // const dateToset = localStorage.getItem("dateTo");

  if(localStorage.getItem("searchUser")){
    console.log('refetchData searchUser1 '+ localStorage.getItem("searchUser"));
     const userInfo = localStorage.getItem("searchUser");
  const userInfoParsed = JSON.parse(userInfo);
  console.log(userInfoParsed);
    var searchUser = ref(userInfoParsed.value);
  } else {
    var searchUser = ref([]);
  }
  


  if(localStorage.getItem("date")){
    console.log(localStorage.getItem("date"));
    const dateset = localStorage.getItem("date");
    var date =  ref(dateset)
  } else {
    var date =  ref(null)
  }

  if(localStorage.getItem("dateTo")){
    console.log(localStorage.getItem("dateTo"));
     const dateToset = localStorage.getItem("dateTo");
     var dateTo =  ref(dateToset)
  } else {
   
    var dateTo =  ref(null)
  }
  
  const perPage = ref(1000);
  const totalInvoices = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100,1000];
  const searchQuery = ref("");


  const searchTime = ref(null)
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const statusFilter = ref({ value: null, label: "ทั้งหมด" });

  // const date =  ref(moment().format('YYYY/MM/DD 00:00:00')) // ref(null) //
  // const dateTo = ref(moment().format('YYYY/MM/DD 23:59:59')) 



  // const nodate = ref(moment().format('YYYY/MM/DD 00:00:00')) 
  // const nodateTo = ref(moment().format('YYYY/MM/DD 23:59:59')) 
  

  //date = date.value.getHours() - 7
  //dateTo = dateTo.value.getHours() - 7

 
  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value
      ? refInvoiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    };
  });

  const refetchData = () => {

    console.log('searchUser '+ localStorage.getItem("searchUser"));

    if(localStorage.getItem("searchUser") == searchUser){
      console.log('searchUser1 '+ localStorage.getItem("searchUser"));
     
    } else {
      localStorage.setItem("searchUser", JSON.stringify(searchUser));
      console.log('searchUser2 '+ JSON.stringify(searchUser));
    }

    if(localStorage.getItem("date")  == date){
      console.log(localStorage.getItem("date"));
    } else {
      localStorage.setItem("date", date.value);
    }

    if(localStorage.getItem("dateTo")  == dateTo ){
      console.log(localStorage.getItem("dateTo"));
    } else {
      localStorage.setItem("dateTo", dateTo.value);
    }


    // console.log(localStorage.getItem("date", date));
    // console.log(date.value);

    // if(date.value == 'null'){
    //   if(localStorage.getItem("date", date) != 'null'){
    //     console.log(localStorage.getItem("date", date));
    //     console.log(date.value);
    //   } else if(localStorage.getItem("date", date) != date.value) {
    //     localStorage.setItem("date", date.value);
    //   }
     
    // }
    // localStorage.setItem("date", JSON.stringify(date.value));
    // localStorage.setItem("dateTo", JSON.stringify(dateTo.value));
    // localStorage.setItem("searchUser", JSON.stringify(searchUser.value));
    refInvoiceListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, statusFilter, date, dateTo,searchUser, searchTime], () => {
    refetchData();
  });
  const dataApiB = ref([]);

  const fetchInvoices = (ctx, callback) => {
    
   

    store
      .dispatch("order-list/fetchOrders", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value.value,
        date: date.value,
        dateTo: dateTo.value,
        search: searchUser.value,
        time: searchTime.value
      })
      .then((response) => {

        console.log(searchQuery.value);
        const { data, total } = response;

        const dataFil = data;
        dataApiB.value = dataFil;
        callback(dataFil);
        totalInvoices.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };


  const fetchWaitInvoices = (ctx, callback) => {

    console.log('refetchData');


    if(localStorage.getItem("searchUser")){
      console.log('refetchData searchUser1 '+ localStorage.getItem("searchUser"));
   
    }



   
   
   

    store
      .dispatch("order-list/fetchWaitOrders", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value.value,
        date: date.value,
        dateTo: dateTo.value,
        search: searchUser.value,
        time: searchTime.value
      })
      .then((response) => {

        console.log(searchQuery.value);
        const { data, total } = response;

        const dataFil = data;
        dataApiB.value = dataFil;
        callback(dataFil);
        totalInvoices.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchPrintedOrders = (ctx, callback) => {
    store
      .dispatch("order-list/fetchPrintedOrders", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value.value,
        date: date.value,
        dateTo: dateTo.value,
        search: searchUser.value,
        time: searchTime.value
      })
      .then((response) => {

        console.log(searchQuery.value);
        const { data, total } = response;

        const dataFil = data;
        dataApiB.value = dataFil;
        callback(dataFil);
        totalInvoices.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  

  

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = (status) => {
    if (status === "Partial Payment")
      return { variant: "warning", icon: "PieChartIcon" };
    if (status === "Paid")
      return { variant: "success", icon: "CheckCircleIcon" };
    if (status === "Downloaded")
      return { variant: "info", icon: "ArrowDownCircleIcon" };
    if (status === "Draft") return { variant: "primary", icon: "SaveIcon" };
    if (status === "Sent") return { variant: "secondary", icon: "SendIcon" };
    if (status === "Past Due") return { variant: "danger", icon: "InfoIcon" };
    return { variant: "secondary", icon: "XIcon" };
  };

  const resolveClientAvatarVariant = (status) => {
    if (status === "Partial Payment") return "primary";
    if (status === "Paid") return "danger";
    if (status === "Downloaded") return "secondary";
    if (status === "Draft") return "warning";
    if (status === "Sent") return "info";
    if (status === "Past Due") return "success";
    return "primary";
  };

  return {
    fetchInvoices,
    fetchWaitInvoices,
    fetchPrintedOrders,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,

    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,

    tableColumnsPrint,
    date,
    dataApiB,
    changeTextP,
    searchUser,
    searchTime,
    dateTo
  };
}
